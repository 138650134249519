import { Text } from "@mantine/core";
import React from "react";

export default function StatsTable({ dict, stats }) {

	return (
		<table cellPadding={0} cellSpacing={0} style={{border: "1px solid #777", borderRadius: 4, width: "100%"}}>
			<tbody>
				<tr>
					<td align="center">
						<Text size="md"><b>{stats.played - stats.lost}/{stats.played}</b></Text>
						<Text size="sm"><b>({stats.played > 0 ? Math.round((stats.played - stats.lost) * 100.0 / stats.played * 10) / 10 : 0}%)</b></Text>
					</td>
					<td align="center"><Text size="xl"><b>{stats.currentStreak}</b></Text></td>
					<td align="center"><Text size="xl"><b>{stats.maxStreak}</b></Text></td>
				</tr>
				<tr>
					<td align="center"><Text size="xs">{dict.found}</Text></td>
					<td align="center"><Text size="xs">{dict.currentStreak}</Text></td>
					<td align="center"><Text size="xs">{dict.maxStreak}</Text></td>
				</tr>
			</tbody>
		</table>
	);
}