import React, { useEffect, useState } from "react";
import { Button, Group, Modal, Stack, Text, TextInput } from "@mantine/core";
import useS3 from "../../hooks/useS3";
import { crypt } from "../../utils/helpers";

export default function StatsExport({ dict, opened, setOpened, stats, setStats, played, setPlayed }) {
	const { uploadFile, downloadFile } = useS3();
	const [key, setKey] = useState(null);
	const [loading, setLoading] = useState(false);
	const [importMode, setImportMode] = useState(false);
	const [imported, setImported] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setKey(null);
		setImportMode(false);
		setLoading(false);
		setImported(false);
		setError(null);
	}, [opened]);

	const exportStats = async () => {
		setLoading(true);
		const newKey = await uploadFile(JSON.stringify({ stats, played }));
		setLoading(false);
		if (newKey)
			setKey(newKey);
		else
			setError(dict.exportError);
	};

	const importStats = async () => {
		setLoading(true);
		const keyWithoutSpaces = key?.replace(/\s/g, "")?.toUpperCase();
		const data = JSON.parse(await downloadFile(keyWithoutSpaces));
		setLoading(false);
		if (data) {
			localStorage.setItem("stats", JSON.stringify(data.stats));
			setStats(data.stats);
			setImported(true);
			if (data.played.length) {
				localStorage.setItem("played", crypt("play3d", JSON.stringify(data.played)));
				setPlayed(data.played);
			}
		}
		else
			setError(dict.importError);
	};

	if (importMode) return (
		<Modal opened={opened} onClose={() => setOpened(false)} title={<Text weight={700} size="lg">{dict.importExport}</Text>}>
			{imported
				? <Stack>
					<Text>{dict.imported}</Text>
					<Group position="center" mt="xl">
						<Button onClick={() => setOpened(false)} sx={{ alignSelf: "center" }}>{dict.close}</Button>
					</Group>
				</Stack>
				: <Stack>
					<Text>{dict.importHeader}</Text>
					<TextInput value={key?.toUpperCase()} onChange={e => setKey(e.currentTarget.value)}
						label={dict.exportCode} placeholder={error ? null : "B4NDL3"} error={error} />
					<Group position="center" mt="xl">
						<Button onClick={() => setOpened(false)} sx={{ alignSelf: "center" }}>{dict.close}</Button>
						<Button onClick={importStats} sx={{ alignSelf: "center" }} loading={loading}>{dict.import}</Button>
					</Group>
				</Stack>
			}
		</Modal>
	);

	return (
		<Modal opened={opened} onClose={() => setOpened(false)} title={<Text weight={700} size="lg">{dict.importExport}</Text>}>
			{key
				? <Stack>
					<Text>{dict.exported}</Text>
					<Text size="xl" weight={700} style={{ fontFamily: "monospace", textAlign: "center", letterSpacing: 4 }}>{key}</Text>
					<Group position="center" mt="xl">
						<Button onClick={() => setOpened(false)} sx={{ alignSelf: "center" }}>{dict.close}</Button>
					</Group>
				</Stack>
				: <>
					<div style={{ display: "flex", gap: 4 }}>
						<Text>{dict.exportIntro}</Text>
						<Button onClick={exportStats} loading={loading}>{dict.export}</Button>
						{error && <Text color="red">{error}</Text>}
					</div>
					<div style={{ display: "flex", gap: 4, marginTop: 14 }}>
						<Text>{dict.importIntro}</Text>
						<Button onClick={() => setImportMode(true)}>{dict.import}</Button>
					</div>
				</>
			}
		</Modal>
	);
}