import { Howl } from "howler";

export default function AudioManager(folder, onWait, onPlay, onStop) {
	const audios = [undefined];
	let stepPlaying = null;

	this.Preload = step => {
		if (!audios[step]) audios[step] = new Howl({
			src: [`https://sound.bandle.app/song/${folder}/${step}.mp3`], format: ["mp3"],
			preload: true, html5: true, mute: false, volume: 0.7,
			onplay: () => { onPlay(step); }, onend: onStop, onpause: onStop,
			onloaderror: (i, e) => {
				onStop();
				window.gtag("event", "onloaderror ", { event_category: "errorLog", event_label: JSON.stringify({ i: i, error: e, v: process.env.REACT_APP_VERSION }) });
			},
			onplayerror: (i, e) => {
				onStop();
				window.gtag("event", "onplayerror", { event_category: "errorLog", event_label: JSON.stringify({ i: i, error: e, v: process.env.REACT_APP_VERSION }) });
			}
		});
	};
	this.Play = (step, fromStart) => {
		if (!audios[step])
			this.Preload(step);
		if (audios[step].state() !== "loaded")
			onWait();
		if (fromStart) audios[step].seek(0);
		audios[step].play();
		stepPlaying = step;
	};
	this.Pause = () => {
		if (stepPlaying != null)
			audios[stepPlaying].pause();
	};
	this.Rewind = (seconds) => {
		if (stepPlaying != null) {
			const currentSeek = audios[stepPlaying].seek();
			audios[stepPlaying].seek(Math.max(currentSeek - seconds, 0));
		}
	};
	this.FastForward = (seconds) => {
		if (stepPlaying != null) {
			const currentSeek = audios[stepPlaying].seek();
			audios[stepPlaying].seek(Math.min(currentSeek + seconds, audios[stepPlaying].duration()));
		}
	};

	this.GetProgress = () => {
		return (audios[stepPlaying].seek() / audios[stepPlaying].duration()) * 100;
	};

	this.Preload(1);
}