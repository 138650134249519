import React from "react";
import { Text, Group, Paper, CloseButton } from "@mantine/core";

export default function Hints({ dict, answer, settingsUpdated }) {

	const formattedView = answer?.view < 1000
		? `${answer?.view}M`
		: `${answer?.view / 1000}B`;

	const closeTip = () => {
		localStorage.setItem("tipsHidden", "true");
		settingsUpdated();
	};

	return (
		answer?.year && localStorage.getItem("tipsHidden") !== "true" &&
		<Paper shadow={"sm"} radius={8} py={2} px={8} withBorder mx={12} mb={2} style={{"position": "relative"}}>
			<CloseButton variant="filled" size={14} radius={8} style={{ position:"absolute", right:-5, top:-5}} onClick={closeTip} />
			<Group position="apart" spacing={8}>
				<Text align="center" size="sm">{dict.released}<br /><b>{answer.year}</b></Text>
				<Text align="center" size="sm">{dict.views}<br /><b>{formattedView}</b></Text>
				<Text align="center" size="sm">{dict.difficulty}<br /><b>{dict[`difficulty${answer.par}`]} (par {answer.par})</b></Text>
			</Group>
		</Paper>

	);
}