import React, { useState, useEffect } from "react";
import { useDictionary } from "./i18n/dictionary";
import { MantineProvider, ColorSchemeProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import Game from "./components/game/game";
import { getLang } from "./utils/helpers";

export default function Bandle() {
	const dict = useDictionary();
	const [colorScheme, setColorScheme] = useState(localStorage.getItem("colorScheme") || "light");

	useEffect(() => {
		if (dict.guessPlaying && getLang(true) !== "en") {
			document.title = `Bandle - ${dict.metaOgTitle}`;
			updateMetaTags(dict);
		}
	}, [dict]);

	const updateMetaTags = dictionary => {
		[
			{ name: "description", content: dictionary.metaDescription },
			{ name: "keywords", content: dictionary.metaKeywords },
			{ property: "og:title", content: dictionary.metaOgTitle },
			{ property: "og:description", content: dictionary.metaOgDescription },
		].forEach(tag => {
			let element = tag.name
				? document.querySelector(`meta[name="${tag.name}"]`)
				: document.querySelector(`meta[property="${tag.property}"]`);
			element.setAttribute("content", tag.content);
		});
	};

	const toggleColorScheme = (value) => {
		value ||= (colorScheme === "dark" ? "light" : "dark");
		setColorScheme(value);
		localStorage.setItem("colorScheme", value);
	};

	return (
		<ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
			<MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
				<NotificationsProvider>
					{dict.play && <Game dict={dict} />}
				</NotificationsProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	);
}