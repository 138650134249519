export function formatDate(date) {
	let d = new Date(date);
	let month = (d.getMonth() + 1).toString();
	let day = d.getDate().toString();
	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;
	return [d.getFullYear(), month, day].join("-");
}
export function formatDate2(date) {
	let d = new Date(date);
	let month = (d.getMonth() + 1).toString();
	if (month.length < 2) month = "0" + month;
	return [d.getFullYear(), month].join("");
}

export function getLang(withPortuguese) {
	let lang = (navigator.language || navigator.userLanguage || "en").substring(0, 2);
	const languages = ["fr", "en", "es", "de"];
	if (withPortuguese) languages.push("pt");
	if (languages.indexOf(lang) === -1) lang = "en";
	return lang;
}

export function detectOS() {
	const { userAgent, platform } = navigator;
	const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
	const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
	const iosPlatforms = ["iPhone", "iPad", "iPod"];

	if (macosPlatforms.includes(platform)) return "Mac OS";
	if (iosPlatforms.includes(platform)) return "iOS";
	if (windowsPlatforms.includes(platform)) return "Windows";
	if (/Android/.test(userAgent)) return "Android";
	if (/Linux/.test(platform)) return "Linux";

	return "other";
}

export function crypt(salt, text) {
	if (!text) return null;
	const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
	const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
	const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
	return text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
}

export function decrypt(salt, encoded) {
	if (!encoded) return null;
	const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
	const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded.match(/.{1,2}/g).map((hex) => parseInt(hex, 16)).map(applySaltToChar)
		.map((charCode) => String.fromCharCode(charCode)).join("");
}

export function substringBetween(s, start, end) {
	if (!s || s.indexOf(start) === -1 || s.indexOf(end) === -1) return null;
	const st = s.indexOf(start) + start.length;
	const ed = s.indexOf(end, st);
	return s.substring(st, ed);
}

function getSquareColor(i) {
	switch (i) {
		case "w": return "⬜";
		case "g": return "🟩";
		case "y": return "🟨";
		case "r": return "🟥";
		case "b": return "⬛";
		default: return "";
	}
}

function getSquare(guesses) {
	return guesses.map(x => getSquareColor(x)).join("");
}
export function getShareText(dict, answer, status) {
	const stats = JSON.parse(localStorage.getItem("stats"));
	const shareStreak = localStorage.getItem("shareStreak") === "true";
	const shareFound = localStorage.getItem("shareFound") === "true";
	const found = stats.played > 0 ? Math.round((stats.played - stats.lost) * 100.0 / stats.played * 10) / 10 : 0;

	return `Bandle #${answer.id} ${status.loose ? "x" : status.step}/${answer.instruments.length}\n` +
		`${getSquare(status.guesses)}\n` +
		(shareFound ? `${dict.found}: ${stats.played - stats.lost}/${stats.played} (${found}%)\n` : "") +
		(shareStreak && stats.maxStreak > 0 ? `${dict.currentStreak}: ${stats.currentStreak} (${dict.max} ${stats.maxStreak})\n` : "") +
		`#Bandle #Heardle #Wordle ${answer.hashtag || ""}\n`;
}
export function canShare() {
	const ua = navigator.userAgent.toLowerCase();
	const mobile = /android|webos|iphone|ipad|ipod|blackberry|mobi|opera mini/i.test(ua);
	const safari = /^((?!chrome|android).)*safari/i.test(ua); // previous test doesn't work on iPad for some reason
	const firefox = /firefox/i.test(ua);
	return navigator.share && ((mobile && !firefox) || safari);
}