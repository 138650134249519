import React from "react";
import { Button, Center } from "@mantine/core";
import { Check, Share } from "tabler-icons-react";
import { canShare, getShareText } from "../../utils/helpers";
import { showNotification } from "@mantine/notifications";

export default function ShareButton({ dict, answer, currentStatus }) {

	const share = () => {
		const text = getShareText(dict, answer, currentStatus);
		if (canShare())
			navigator.share({ text: text, url: window.location.href });
		else {
			navigator.clipboard.writeText(`${text}\n${window.location.href}`);
			showNotification({
				icon: <Check size={18} />, color: "teal", message: dict.copiedToClipboard,
				sx: { bottom: 90 }
			});
		}
		window.gtag("event", "share", { event_category: "engagement", event_label: answer.song });
	};

	return (
		<Center>
			<Button mt={10} style={{ backgroundColor: "#2e8a2b" }} radius="xs"
				rightIcon={<Share size={18} />}
				onClick={share}>{dict.share}</Button>
		</Center>
	);
}