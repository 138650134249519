import React from "react";
import { Group } from "@mantine/core";
import { getLang } from "../../utils/helpers";

export default function MusicAppButtons({ answer }) {
	const open = async (url) => {
		window.open(url, "_blank");
	};

	const openWikipedia = () => {
		let lang = getLang(true);
		lang = answer[`wiki_${lang}`] ? lang : "en";
		window.open(`https://${lang}.m.wikipedia.org/wiki/${answer[`wiki_${lang}`]}`, "_blank");
	};

	return (
		<Group my={12} spacing={16} position="center" >
			<img src="\app\youtube.png" alt="YouTube" style={{ width: 40, height: 40, cursor: "pointer" }} onClick={() => open(`https://www.youtube.com/watch?v=${answer.youtube}&t=${answer.youtubeStart}s`)} />
			<img src="\app\spotify.png" alt="Spotify" style={{ width: 40, height: 40, cursor: "pointer" }} onClick={() => open(`https://open.spotify.com/track/${answer.spotifyId}`)} />
			<img src="\app\apple.png" alt="Apple Music" style={{ width: 40, height: 40, cursor: "pointer" }} onClick={() => open(`https://music.apple.com/us/song/${answer.appleMusicId}`)} />
			<img src="\app\youtubeMusic.png" alt="YouTube Music" style={{ width: 40, height: 40, cursor: "pointer" }} onClick={() => open(`https://music.youtube.com/watch?v=${answer.youtube}`)} />
			<img src="\app\wikipedia.png" alt="Wikipedia" style={{ width: 40, height: 40, cursor: "pointer" }} onClick={openWikipedia} />
		</Group>
	);
}
