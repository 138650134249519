import React, { useEffect, useState } from "react";
import { Modal, Stack, Group, Text, Switch, useMantineColorScheme, Anchor } from "@mantine/core";

export function Settings({ dict, opened, setOpened, settingsUpdated }) {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const gamePlayed = (JSON.parse(localStorage.getItem("stats"))?.played || 0);
	const [shareFound, setShareFound] = useState(localStorage.getItem("shareFound") === "true" || gamePlayed == 0);
	const [shareStreak, setShareStreak] = useState(localStorage.getItem("shareStreak") === "true" || gamePlayed == 0);
	const [tipsHidden, setTipsHidden] = useState(false);

	useEffect(() => {
		if (opened)
			setTipsHidden(localStorage.getItem("tipsHidden") === "true");
	}, [opened]);

	useEffect(() => {
		localStorage.setItem("tipsHidden", tipsHidden ? "true" : "");
		localStorage.setItem("shareFound", shareFound ? "true" : "");
		localStorage.setItem("shareStreak", shareStreak ? "true" : "");
		settingsUpdated();
	}, [tipsHidden, shareStreak, shareFound]);

	return (
		<Modal opened={opened} onClose={() => setOpened(false)} title={<Text weight={700} size="lg">{dict.settings}</Text>}>
			<Stack spacing={0}>
				<table>
					<tbody>
						<tr>
							<td valign="bottom"><Text>{dict.dark}</Text></td>
							<td valign="bottom"><Switch checked={colorScheme === "dark"} onChange={e => toggleColorScheme(e.currentTarget.checked ? "dark" : "light")} /></td>
						</tr>
						<tr>
							<td valign="bottom"><Text>{dict.shareFound}</Text></td>
							<td valign="bottom"><Switch checked={shareFound} onChange={e => setShareFound(e.currentTarget.checked)} /></td>
						</tr>
						<tr>
							<td valign="bottom"><Text>{dict.shareStreak}</Text></td>
							<td valign="bottom"><Switch checked={shareStreak} onChange={e => setShareStreak(e.currentTarget.checked)} /></td>
						</tr>
						<tr>
							<td valign="bottom"><Text>{dict.hideTips}</Text></td>
							<td valign="bottom"><Switch checked={tipsHidden} onChange={e => setTipsHidden(e.currentTarget.checked)} /></td>
						</tr>
					</tbody>
				</table>
				<Group mt={30} position="apart">
					<Text weight={700} size="lg">{dict.about}</Text>
					<Text size="xs" mr={20}>v{process.env.REACT_APP_VERSION}</Text>
				</Group>
				<Text>{dict.aboutText}<Anchor href="https://twitter.com/bandlegame" target="_blank">@BandleGame</Anchor></Text>
			</Stack>
		</Modal>
	);
}