import React, { useState, useEffect, useRef } from "react";
import { Stack, Group, Button, Center, useMantineColorScheme } from "@mantine/core";
import Select from "react-select";
import { showNotification } from "@mantine/notifications";
import { songs } from "../../songs/songs";
import { X, ExclamationMark } from "tabler-icons-react";
import Fuse from "fuse.js";

export function Guess({ dict, answer, win, currentStatus, nextStep, scrollToBottom }) {
	const { colorScheme } = useMantineColorScheme();
	const [guess, setGuess] = useState(null);
	const [songList, setSongList] = useState(null);
	const [matches, setMatches] = useState([]);
	const cmbGuess = useRef(null);

	const darkStyles = {
		control: (provided) => ({
			...provided,
			width: "100%",
			backgroundColor: "#343a40",
			color: "lightgray",
			borderColor: "gray", boxShadow: "none",
			"&:hover": { borderColor: "lightgray" }
		}),
		input: (provided) => ({
			...provided, color: "lightgray",
		}),
		placeholder: (provided) => ({
			...provided, color: "lightgray",
		}),
		singleValue: (provided) => ({
			...provided, color: "lightgray",
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: "#343a40",
			color: "lightgray"
		}),
		option: (provided) => ({
			...provided,
			backgroundColor: "#343a40",
			"&:hover": {
				backgroundColor: "#747a70"
			}
		})
	};
	const lightStyles = {
		control: (provided) => ({
			...provided,
			borderColor: "lightgray", boxShadow: "none",
			"&:hover": { borderColor: "darkgray" }
		}),
		option: (provided) => ({
			...provided,
			"&:hover": {
				backgroundColor: "#EEE"
			}
		})
	};

	useEffect(scrollToBottom, []);

	useEffect(() => {
		if (songList !== null || !answer?.song) return;
		// add song to songs list if song or songDisplay isn't in the list
		if (songs.indexOf(answer?.song) === -1 && songs.indexOf(answer?.songDisplay) === -1)
			songs.splice(1000, 0, answer?.song);
		// replace song with songDisplay if song is still in the list
		if (answer.songDisplay && songs.indexOf(answer?.song) !== -1)
			songs.splice(songs.indexOf(answer.song), 1, answer.songDisplay);
		setSongList(songs.map(s => ({ label: s, value: s.toLowerCase().replace(/[^a-z0-9]/gi, "") })));
	}, [songList, answer?.song]);

	const fuse = new Fuse(songs.map(s => ({ label: s, value: s.toLowerCase().replace(/[^a-z0-9]/gi, "") })), {
		keys: ["value"],
		ignoreLocation: true,
		threshold: 0.3
	});

	const doGuess = checkEmptyAnswer => {
		if (checkEmptyAnswer && guess === null) return;
		const theGuess = (guess?.label || "").toLowerCase().trim();
		const theAnswer = (answer?.songDisplay || answer?.song)?.toLowerCase().trim();
		if (theGuess === theAnswer)
			win();
		else {
			const guessArtist = theGuess.split("-")[0]?.trim();
			console.log("guessArtist:", guessArtist, theAnswer.indexOf("feat. " + guessArtist))
			const correctArtist = guessArtist === theAnswer.split("-")[0]?.trim()
				|| theAnswer.indexOf("ft. " + guessArtist) >= 0
				|| theAnswer.indexOf("feat. " + guessArtist) >= 0
				|| theAnswer.indexOf(" " + guessArtist + ")") >= 0
				|| theAnswer.indexOf(" " + guessArtist + " ") >= 0
				|| theAnswer.indexOf(" " + guessArtist + ",") >= 0
				|| theAnswer.indexOf(guessArtist + " and") === 0
				|| theAnswer.indexOf(guessArtist + " &") === 0;
			if (theGuess && currentStatus?.step < answer.instruments.length)
				showNotification({
					message: correctArtist ? dict.correctArtist : dict[`wrong${Math.floor(Math.random() * 10) + 1}`],
					color: correctArtist ? "yellow" : "red",
					icon: correctArtist ? <ExclamationMark size={18} /> : <X size={18} />,
					sx: { bottom: 90 }
				});
			nextStep(!checkEmptyAnswer ? null : correctArtist);
		}
		setGuess(null);
	};
	
	const filterChanged = query => {
		const queryLower = query.toLowerCase().replace(/[^a-z0-9]/gi, "");		
		setMatches(query.length < 2 ? [] : fuse.search(queryLower).slice(0, 15).map(result => result.item.value));
	};

	const iconFromSkin = () => {
		switch (answer?.skin) {
			case "tv": return "tv2.svg";
			case "halloween": return "ghost.png";
			case "xmas": return "xmas2.svg";
			case "love": return "birds.svg";
			case "usa": return "usa.svg";
			default: return null;
		}
	};

	return (
		<Center>
			<Stack sx={{ width: "90%" }} spacing={0} pb="xs">
				<Select ref={cmbGuess} placeholder={dict.guessSong} onChange={setGuess} value={guess} menuPlacement="top" maxMenuHeight={350}
					blurInputOnSelect={true} isClearable={true}
					onInputChange={filterChanged} filterOption={({ value }) => matches.indexOf(value) >= 0}
					components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} styles={colorScheme === "dark" ? darkStyles : lightStyles}
					options={songList} noOptionsMessage={({ inputValue }) => inputValue.length > 1 ? dict.noSong : null} />
				<Group position="apart" mt={15}>
					<Button radius="xs" onClick={() => doGuess()} 
					style={{ backgroundColor: answer.skin === "halloween" ? "#902EBB" : "#97111D" }}>
						{dict.skip}
						</Button>
					{answer.skin && <img src={`\\skin\\${iconFromSkin()}`} height="38" alt={answer.skin} />}
					<Button style={{ backgroundColor: "#2e8a2b" }} radius="xs" onClick={() => doGuess(true)}>{dict.guess}</Button>
				</Group>
			</Stack>
		</Center>
	);
}