import {useState, useEffect} from "react";
import { getLang } from "../utils/helpers";

export function useDictionary() {
	const [dictionary, setDictionary] = useState({});
	
	useEffect(() => {
		import(`./lang.${getLang(true)}.json`).then(dict => setDictionary(dict));
	}, []);

	return dictionary;
}