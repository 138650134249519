import { Stack, Text } from "@mantine/core";
import React from "react";
import { detectOS } from "../../utils/helpers";

export function TopBanner({ dict }) {
	const os = detectOS();
	const isApple = os === "Mac OS" || os === "iOS";

	const storeLink = isApple
		? "https://apps.apple.com/us/app/bandle-guess-the-song/id6471419531"
		: "https://play.google.com/store/apps/details?id=com.bandlegame.bandleguessasong";

	const isMobileDevice = () => {
		if (window.matchMedia("(max-width: 768px)").matches)
			return true;
		const mobileRegex = /android|iphone|ipad|ipod|blackberry|windows phone|webos|opera mini|opera mobi|mobile|silk/i;
		return mobileRegex.test(navigator.userAgent.toLowerCase());
	};

	if (!isMobileDevice())
		return;

	return (
		<Stack mb={-10} p={4} onClick={() => window.open(storeLink, "_blank")}
			style={{ backgroundColor: "green", borderBottom: "1px solid black", cursor: "pointer", textAlign: "center" }}>
			<Text size={15} sx={{ color: "white" }}>{dict.topBanner1} <u>{dict.topBanner2}</u></Text>
		</Stack>
	);
}