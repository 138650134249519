import React, { useEffect, useState } from "react";
import { Group, Button, Tooltip, Loader, Progress, Center, ActionIcon, useMantineColorScheme } from "@mantine/core";
import { PlayerPlay, PlayerPause } from "tabler-icons-react";
import audioManager from "../../utils/audioManager";
import "../../css/player.css";

export function Player({ dict, answer, currentStatus, listenedOnce, setListenedOnce, stepTapped, setStepTapped }) {
	const { colorScheme } = useMantineColorScheme();
	const [audios, setAudios] = useState(null);
	const [loading, setLoading] = useState(false);
	const [playingStep, setPlayingStep] = useState(null);
	const [progress, setProgress] = useState(0);

	const gameEnded = currentStatus?.win || currentStatus?.loose;

	useEffect(() => {
		if (answer?.folder && audios === null) {
			const onPlayStart = (step) => {
				setPlayingStep(step); setLoading(false); setListenedOnce(true);
				window.gtag("event", `play${step}`, { event_category: "engagement", event_label: answer.song });
			};
			setAudios(
				new audioManager(answer.folder, () => setLoading(true), onPlayStart, () => setPlayingStep(null))
			);
		}
	}, [answer?.folder, answer?.song, setListenedOnce, audios]);

	useEffect(() => {
		if (!currentStatus?.step) return;
		if (currentStatus?.step < answer.instruments.length || gameEnded) {
			audios?.Pause();
			audios?.Preload(currentStatus?.step == answer.instruments.length ? answer.instruments.length - 1 : currentStatus?.step);
		}
	}, [gameEnded, currentStatus?.step, audios]);

	useEffect(() => {
		if (stepTapped) {
			const index = stepTapped === answer.instruments.length ? answer.instruments.length - 1 : stepTapped;
			setStepTapped(null);
			if (index === playingStep)
				audios.Pause();
			else {
				audios.Pause();
				audios.Play(index, true);
			}
		}
	}, [audios, playingStep, stepTapped, setStepTapped]);

	useEffect(() => {
		setProgress(0);
	}, [currentStatus?.step]);

	useEffect(() => {
		if (playingStep) {
			const id = setInterval(() => { setProgress(audios.GetProgress()); }, 100);
			return () => clearInterval(id);
		}
	},
		[playingStep, progress, audios]
	);

	const toggleStartStop = () => {
		playingStep ? audios.Pause() : audios.Play(currentStatus?.step == answer.instruments.length ? answer.instruments.length - 1 : currentStatus?.step);
	};

	const handleRewind = () => {
		if (audios) {
			audios.Rewind(5);
			setProgress(audios.GetProgress());
		}
	};

	const handleFastForward = () => {
		if (audios) {
			audios.FastForward(5);
			setProgress(audios.GetProgress());
		}
	};

	if (!gameEnded) return (
		<>
			<Group position="center" mb={2}>
				{currentStatus?.step && (
					<>
						<ActionIcon variant="light" radius="xl" onClick={loading ? undefined : handleRewind}>
							<img src={"\\backward.svg"} alt="forward" width={24} style={colorScheme === "dark" ? { filter: "invert(100%)" } : undefined} />
						</ActionIcon>
						<Tooltip label={dict.playTip} withArrow arrowSize={6} position="bottom" zIndex={1}
							opened={!listenedOnce && currentStatus?.step === 1}
							classNames={{ tooltip: "floating" }}>
							<Button sx={theme => ({
								color: theme.colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.gray[9],
								borderColor: theme.colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.gray[9]
							})}
								variant="outline" radius="xl" onClick={loading ? undefined : toggleStartStop}>
								{(loading && <Loader size={20} />) || (playingStep && <PlayerPause />) || <PlayerPlay />}
							</Button>
						</Tooltip>
						<ActionIcon variant="light" radius="xl"onClick={loading ? undefined : handleFastForward}>
							<img src={"\\forward.svg"} alt="forward" width={24} style={colorScheme === "dark" ? { filter: "invert(100%)" } : undefined} />
						</ActionIcon>
					</>
				)}
			</Group>
			{(listenedOnce || currentStatus?.step !== 1) &&
				<Center><Progress size={2} value={progress} animate={playingStep !== null} sx={{ width: "80%" }} /></Center>}
		</>
	);
}