import React, { useEffect, useState } from "react";
import { Text, Stack } from "@mantine/core";
import MidnightCountDown from "./midnightCountDown";
import StatsChart from "../stats/statsChart";
import ShareButton from "./shareButton";
import StatsTable from "../stats/statsTable";
import OtherGames from "./otherGames";
import MoreBandleButton from "./moreBandleButton";
import BonusButton from "./bonusButton";
import MusicAppButtons from "./musicAppButtons";

export default function EndGameStats({ dict, currentStatus, answer }) {
	const [stats, setStats] = useState(false);

	useEffect(() => {
		setStats(JSON.parse(localStorage.getItem("stats")));
	}, [currentStatus?.loose]);

	if (stats) return (
		<Stack spacing={0} mx="md">
			<Stack align="center" spacing={0}>
				<Text size={15}>{currentStatus?.win ? dict.youWin : dict.youLoose} {dict.todaysSong}</Text>
				<Text size={15} weight={500}>{answer.songDisplay || answer.song}</Text>
			</Stack>
			<MusicAppButtons answer={answer} />
			<StatsChart stats={stats} currentStatus={currentStatus} />
			<ShareButton dict={dict} answer={answer} currentStatus={currentStatus} />
			<BonusButton dict={dict} />
			<MidnightCountDown dict={dict} />
			<MoreBandleButton dict={dict} />
			<StatsTable dict={dict} stats={stats} />
			<OtherGames dict={dict} />
		</Stack>
	);
}