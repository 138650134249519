import { Group, Progress, Text } from "@mantine/core";
import React from "react";

export default function StatsChart({ stats, currentStatus }) {

	const maxGuess = Math.max(stats.guess1, stats.guess2, stats.guess3, stats.guess4, stats.guess5, stats.guess6, stats.lost);
	
	const getBar = (i, val) => {
		const color = i === 7 && currentStatus?.loose ? "red"
			: (currentStatus?.win && currentStatus?.step === i ? "#2e8a2b" : "blue");
		return stats.played > 0 && <Progress radius="xs" size="lg" sx={{ width: "85%" }}
			sections={[
				{ value: Math.max(val / maxGuess * 100.0 - 7, 0), color: color },
				{ value: 7, label: val + "", color: color }
			]} />;
	};

	return [1, 2, 3, 4, 5, 6, 7].map(i =>
		<Group key={i} spacing="xs">
			<Text size={13} sx={{lineHeight: 1.3}}>{i === 7 ? "X" : i}</Text>
			{getBar(i, i === 7 ? stats.lost : stats[`guess${i}`])}
		</Group>
	);
}