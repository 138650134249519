import { useEffect, useState } from "react";
import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

export default function useS3() {
	const [s3Client, setS3Client] = useState(null);
	const bucket = "bandle-scores";

	useEffect(() => {
		setS3Client(new S3Client({
			region: "us-east-1",
			credentials: {
				accessKeyId: process.env.REACT_APP_KEY,
				secretAccessKey: process.env.REACT_APP_SECRET
			}
		}));
	}, []);

	async function uploadFile(content) {
		const blob = new Blob([content], { type: "text/plain" });
		const uploadParams = {
			Bucket: bucket,
			Key: generateKey(),
			Body: blob,
		};

		try {
			await s3Client.send(new PutObjectCommand(uploadParams));
			return uploadParams.Key;
		} catch (error) {
			return null;
		}
	}

	const generateKey = () => {
		const chars = "123456789ABCDEFGHJKLMNPQRSTUVWXYZ";
		let result = "";
		for (let i = 0; i < 6; i++)
			result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	};

	async function downloadFile(key) {
		try {
			const command = new GetObjectCommand({ Bucket: bucket, Key: key });
			const response = await s3Client.send(command);

			if (response.Body instanceof ReadableStream) {
				const reader = response.Body.getReader();
				let chunks = [];
				let done, value;

				while (!done) {
					({ done, value } = await reader.read());
					if (value) chunks.push(value);
				}
				const decoder = new TextDecoder();
				return chunks.map(chunk => decoder.decode(chunk, { stream: true })).join("");
			} else 
				return null;
		} catch (error) {
			return null;
		}
	}

	return { uploadFile, downloadFile };
}