import React, { useEffect, useState } from "react";
import { Modal, Text, Stack, Button, Group } from "@mantine/core";
import StatsChart from "./statsChart";
import StatsExport from "./statsExport";
import StatsTable from "./statsTable";
import { decrypt } from "../../utils/helpers";

export function AllTimeStats({ dict, opened, setOpened }) {
	const [stats, setStats] = useState(false);
	const [exportPopup, setExportPopup] = useState(false);
	const [played, setPlayed] = useState(false);

	useEffect(() => {
		if (opened) {
			setStats(JSON.parse(localStorage.getItem("stats")));
			setPlayed(JSON.parse(decrypt("play3d", localStorage.getItem("played"))) || []);
		}
	}, [opened]);

	if (stats) return (
		<Modal opened={opened !== false} onClose={() => setOpened(false)} overflow="inside" title={<Text weight={700} size="lg">{dict.stats}</Text>}>
			<Stack spacing={0}>
				<StatsChart stats={stats} />
				<Group mt="md">
					<StatsTable dict={dict} stats={stats} />
				</Group>
				<Group position="center" mt="md">
					<Button onClick={() => setExportPopup(true)}>{dict.importExport}</Button>
				</Group>
			</Stack>
			<StatsExport dict={dict} opened={exportPopup} setOpened={setExportPopup} stats={stats} setStats={setStats} played={played} setPlayed={setPlayed} />
		</Modal>
	);
}