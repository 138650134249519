import React from "react";
import { Modal, Text, Container, Button, Anchor, Group } from "@mantine/core";
import { Users, Music, ThumbUp, BrandTwitter } from "tabler-icons-react";

export function Instructions({ dict, opened, setOpened }) {
	return (
		<Modal opened={opened} onClose={() => setOpened(false)} title={<Text weight={700} size="lg">{dict.howToPlay}</Text>}>
			<table>
				<tbody>
					<tr>
						<td valign="top"><Container mt="xs"><Users /></Container></td>
						<td><Text mb="lg">{dict.howToPlay1}</Text></td>
					</tr>
					<tr>
						<td valign="top"><Container mt="xs"><Music /></Container></td>
						<td><Text mb="lg">{dict.howToPlay2}</Text></td>
					</tr>
					<tr>
						<td valign="top"><Container mt="xs"><ThumbUp /></Container></td>
						<td><Text mb="lg">{dict.howToPlay3}</Text></td>
					</tr>
					<tr>
						<td valign="top"><Container mt="xs"><BrandTwitter /></Container></td>
						<td>
							<Text mb="lg">
								{dict.howToPlay4a}&nbsp;
								<Anchor href="https://twitter.com/bandlegame" target="_blank">@BandleGame</Anchor>
								&nbsp;{dict.howToPlay4b}
							</Text>

						</td>
					</tr>
				</tbody>
			</table>
			<Group position="center">
				<Button style={{ backgroundColor: "#2e8a2b" }} radius="xs" onClick={() => setOpened(false)}>{dict.play}</Button>
			</Group>
			<Group position="right">
				<Anchor href="privacy.html" target="_blank" size="xs">{dict.privacy}</Anchor>
			</Group>

		</Modal>
	);
}