import { Button, Group, Modal, Text } from "@mantine/core";
import React from "react";
import { detectOS } from "../../utils/helpers";

export default function YesterdayPopup({ dict, closePopup }) {
	const os = detectOS();
	const isApple = os === "Mac OS" || os === "iOS";

	const storeLink = isApple
		? "https://apps.apple.com/us/app/bandle-guess-the-song/id6471419531"
		: "https://play.google.com/store/apps/details?id=com.bandlegame.bandleguessasong";

	return (
		<Modal opened={true} onClose={closePopup} withCloseButton={false} >
			<Text weight={700} mb="xl">{dict.missYesterday1}</Text>
			<Text>{dict.missYesterday2}</Text>
			<Group position="center" mt="xl">
				<Button variant="outline" onClick={closePopup}>{dict.noThanks}</Button>
				<Button onClick={() => window.location.href = storeLink}>{dict.yesPlease}</Button>
			</Group>
		</Modal>
	);
}