import { Button, Center } from "@mantine/core";
import React from "react";
import { detectOS } from "../../utils/helpers";
import { BrandAppstore, BrandGooglePlay } from "tabler-icons-react";

export default function MoreBandleButton({ dict }) {
	const os = detectOS();
	const isApple = os === "Mac OS" || os === "iOS";

	const storeLink = isApple
		? "https://apps.apple.com/us/app/bandle-guess-the-song/id6471419531"
		: "https://play.google.com/store/apps/details?id=com.bandlegame.bandleguessasong";

	return (
		<Center>
			<Button mb={10} style={{ backgroundColor: "#2e8a2b" }} radius="xs"
				rightIcon={isApple ? <BrandAppstore size={18} /> : <BrandGooglePlay size={18} />}
				onClick={() => window.open(storeLink, "_blank")}>
				{dict.getApp}
			</Button>
		</Center>
	);
}