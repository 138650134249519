import React, { useEffect, useState } from "react";
import { Group, Text, Center } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Settings as SettingIcon, Share, ChartBar, QuestionMark, Check } from "tabler-icons-react";
import { Instructions } from "../popups/instructions";
import { Settings } from "./settings";
import { getShareText, canShare } from "../../utils/helpers";
import { AllTimeStats } from "../stats/allTimeStats";

export function Header({ dict, currentStatus, answer, settingsUpdated }) {
	const [instructionsOpened, setInstructionsOpened] = useState(false);
	const [statsOpened, setStatsOpened] = useState(false);
	const [paramOpened, setParamOpened] = useState(false);

	useEffect(() => {
		if (!localStorage.getItem("stats")) {
			localStorage.setItem("stats", JSON.stringify({
				lastId: 0, played: 0, currentStreak: 0, maxStreak: 0, guess1: 0, guess2: 0, guess3: 0, guess4: 0, guess5: 0, guess6: 0, lost: 0
			}));
			setInstructionsOpened(true);
		}
	}, []);

	const share = () => {
		const gameEnded = currentStatus?.win || currentStatus?.loose;
		const text = gameEnded
			? getShareText(dict, answer, currentStatus)
			: `${dict.shareTitle}\n${dict.shareBody}\n`;
		if (canShare())
			navigator.share({ text: text, url: window.location.href });
		else {
			navigator.clipboard.writeText(`${text}${window.location.href}`);
			showNotification({
				icon: <Check size={18} />, color: "teal", message: dict.copiedToClipboard,
				sx: { bottom: 90 }
			});
		}
		window.gtag("event", "share", { event_category: "engagement", event_label: answer.song });
	};

	return (
		<Center>
			<Group position="apart" mt={14} sx={{ width: "90%" }}>
				<div>
					<SettingIcon size={28} onClick={() => setParamOpened(true)} style={{ cursor: "pointer", marginRight: 6 }} />
					<Share size={28} onClick={share} style={{ cursor: "pointer" }} />
				</div>
				<Text weight={600} size={"xl"}>BANDLE</Text>
				<div>
					<ChartBar size={28} onClick={() => setStatsOpened(true)} style={{ cursor: "pointer", marginRight: 6 }} />
					<QuestionMark size={28} onClick={() => setInstructionsOpened(true)} style={{ cursor: "pointer" }} />
				</div>
			</Group>
			<Instructions dict={dict} opened={instructionsOpened} setOpened={setInstructionsOpened} />
			<AllTimeStats dict={dict} opened={statsOpened} setOpened={setStatsOpened} />
			<Settings dict={dict} opened={paramOpened} setOpened={setParamOpened} settingsUpdated={settingsUpdated} />
		</Center>
	);
}