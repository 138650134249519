import { useEffect } from "react";

export default function Advert() {
	useEffect(() => {
		window.adthrive = window.adthrive || {};
		window.adthrive.cmd = window.adthrive.cmd || [];
		window.adthrive.plugin = "adthrive-ads-manual";
		window.adthrive.host = "ads.adthrive.com";

		// Remove existing script if it exists
		let existingScript = document.querySelector("script[src*=\"ads.adthrive.com\"]");
		while (existingScript) {
			existingScript.parentNode.removeChild(existingScript);
			existingScript = document.querySelector("script[src*=\"ads.adthrive.com\"]");
		}
		
		// Load AdThrive script
		const script = document.createElement("script");
		script.async = true;
		script.referrerPolicy = "no-referrer-when-downgrade";
		script.src = `https://${window.adthrive.host}/sites/6619b6c7848264400268d044/ads.min.js?referrer=${encodeURIComponent(window.location.href)}&cb=${Math.floor(Math.random() * 100) + 1}`;
		document.head.appendChild(script);
	}, []);

	return null;
}